import cn from 'classnames'
import { isNumber } from '../../common/utils'
import { Axis, GapType } from './types'

function isValidTailwindGridProperty(val: any) {
  return isNumber(val) && val >= 1 && val <= 13
}

function getGridDimensions(type: Axis, val?: number) {
  const prefix = type === 'column' ? 'cols' : 'rows'

  if (isValidTailwindGridProperty(val)) {
    return `grid-${prefix}-${val}`
  }
  return `grid-${prefix}-none`
}

function getGap(args: { gap?: number; type: GapType }) {
  const { gap, type } = args
  const prefix = type === 'column' ? 'x-' : type === 'row' ? 'y-' : ''
  return `gap-${prefix}${gap}`
}

function getItemDimensions(val: { start?: number; end?: number; span?: number; type: Axis }) {
  const { start, end, span, type } = val
  const prefix = type === 'column' ? 'col' : 'row'
  const className = cn(
    { [`${prefix}-start-${start}`]: isValidTailwindGridProperty(start) },
    { [`${prefix}-end-${end}`]: isValidTailwindGridProperty(end) },
    { [`${prefix}-span-${span}`]: isValidTailwindGridProperty(span) }
  )

  return className
}

export const styles = {
  getGridDimensions,
  getGap,
  getItemDimensions
}
