import { getColor } from '../../common/utils'
import { ButtonSize, ButtonVariant } from './types'

/* Returns the inversed color */
function reverseColor(color: string): string {
  const map: Record<string, string> = {
    white: 'black',
    black: 'white'
  }
  return map[color] || 'white'
}

const defaultClassName = `inline-flex items-center border border-transparent font-medium shadow-sm text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`

const sizes: Record<ButtonSize, string> = {
  sm: 'px-2.5 py-1.5 rounded text-xs',
  md: 'px-3 py-2 rounded-md text-sm leading-4',
  lg: 'px-4 py-2 rounded-md text-sm',
  xl: 'px-4 py-2 rounded-md text-base'
}

const primary = (color: string) =>
  [`bg-${getColor(color, 500)}`, `text-${reverseColor(color)}`, `hover:bg-${getColor(color, 600)}`].join(' ')

const secondary = (color: string) =>
  [`bg-${getColor(color, 100)}`, `text-${getColor(color, 700)}`, `hover:bg-${getColor(color, 200)}`].join(' ')

const plain = () => 'border-gray-300 text-gray-700 bg-white hover:bg-gray-50 focus:ring-indigo-500'

const disabled = `opacity-60`

export const styles = {
  default: defaultClassName,
  variants: {
    primary,
    secondary,
    plain
  } as Record<ButtonVariant, (color: string) => string>,
  disabled,
  sizes
}
