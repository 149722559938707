import React from 'react'
import cn from 'classnames'

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {}

export const FormLabel: React.FC<LabelProps> = (props) => {
  const { children, className: overrideClassname, ...labelProps } = props
  const className = cn('text-sm', overrideClassname)
  return (
    <label className={className} {...labelProps}>
      {children}
    </label>
  )
}
