import React from 'react'
import { styles } from './styles'
import { DialogOverlay, DialogContent } from '@reach/dialog'
import cn from 'classnames'
import '@reach/dialog/styles.css'
import './overrides.css'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  size: 'sm' | 'md' | 'lg' | 'xl'
}
type ModalImpl = React.FC<ModalProps> & {
  Title: React.FC<{ className?: string }>
  Description: React.FC<{ className?: string }>
}

const Modal: ModalImpl = (props) => {
  const { isOpen, onClose, children, size = 'md' } = props

  const overlayStyle = styles.overlay(isOpen)
  const modalStyle = styles.modal(isOpen, size)

  return (
    <DialogOverlay isOpen={isOpen} onDismiss={onClose} className={cn(overlayStyle, styles.container)}>
      <DialogContent className={modalStyle}>
        <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button type="button" className={styles.closeButton} onClick={() => onClose()}>
            <span className="sr-only">Close</span>
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

export { Modal }

Modal.Title = ({ className, ...props }) => (
  <div className={cn('text-lg leading-6 font-medium text-gray-900', className)} {...props} />
)
Modal.Description = ({ className, ...props }) => <div className={cn('text-sm text-gray-500', className)} {...props} />
