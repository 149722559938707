import { Width } from './types'

export const styles = {
  base: 'p-2 focus:border-indigo-500 block sm:text-sm outline-none',
  standard: 'rounded border-2 border-gray-200',
  outline: 'border-t-0 border-r-0 border-l-0 border-b-2 border-gray-200',
  ghost: 'border-none',
  widths: {
    sm: 'w-72',
    md: 'w-80',
    lg: 'w-96',
    full: 'w-full'
  } as Width
}
