import React from 'react'
import { UseSelectPropGetters } from 'downshift'
import cn from 'classnames'
import { selectStyles } from './styles'
import { ISelectItem } from '../../common/types'

interface SelectItemProps {
  highlightedIndex: number
  selectedItems: string[]
  index: number
  item: ISelectItem
  getItemProps: UseSelectPropGetters<ISelectItem>['getItemProps']
}

const SelectItem = (props: SelectItemProps) => {
  const { highlightedIndex, selectedItems, index, item, getItemProps } = props
  return (
    <li
      className={cn(
        selectStyles.menuItem,
        { [`bg-gray-100`]: highlightedIndex === index },
        { [`bg-gray-200`]: selectedItems.includes(item.value) }
      )}
      {...getItemProps({ item, index })}
    >
      {item.label}
    </li>
  )
}

export { SelectItem }
