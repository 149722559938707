import React from 'react'
import { Popover as UIPopover } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import cn from 'classnames'
import { styles } from './styles'

const Popover = ({ className, ...props }: any) => <UIPopover className={cn(styles.popover, className)} {...props} />
const PopoverButton = ({ children, className, ...props }: any) => (
  <UIPopover.Button className={cn(styles.button, className)} {...props}>
    {children}
    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
  </UIPopover.Button>
)
const PopoverPanel = ({ className, ...props }: any) => (
  <UIPopover.Panel className={cn(styles.panel, className)} {...props} />
)

Popover.Button = PopoverButton
Popover.Panel = PopoverPanel

export { Popover }
