import React from 'react'
import cn from 'classnames'
import { styles } from './styles'
import { errorIcon, successIcon, warningIcon } from '../../common/icons'

type AlertType = 'success' | 'warning' | 'error'
interface AlertProps {
  type: AlertType
}
type AlertImpl = React.FC<AlertProps> & { Heading: React.FC; Description: React.FC }

interface ChildProps {
  className?: string
  type?: AlertType
}

const icons: Record<AlertType, JSX.Element> = {
  error: errorIcon,
  warning: warningIcon,
  success: successIcon
}

export const Alert: AlertImpl = (props) => {
  const { type, children } = props

  return (
    <div className={cn(styles.base.container, styles[type].container)}>
      <div className="flex">
        <div className="flex-shrink-0">{icons[type]}</div>
        {children && (
          <div className="ml-3">
            {React.Children.map(children, (child) => React.cloneElement(child as React.ReactElement, { type }))}
          </div>
        )}
      </div>
    </div>
  )
}

const Heading: React.FC<ChildProps> = (props) => {
  const { children, className, type = 'success' } = props

  return <h3 className={cn(styles.base.heading, styles[type].heading, className)}>{children}</h3>
}

const Description: React.FC<ChildProps> = (props) => {
  const { children, className, type = 'success' } = props

  return <div className={cn(styles.base.description, styles[type].description, className)}>{children}</div>
}

Alert.Heading = Heading
Alert.Description = Description
