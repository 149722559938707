const container = 'rounded-md p-4'
const heading = 'text-sm font-medium'
const description = 'mt-2 text-sm'

const error = {
  container: 'bg-red-50',
  heading: 'text-red-800',
  description: 'text-red-700'
}

const warning = {
  container: 'bg-yellow-50',
  heading: 'text-yellow-800',
  description: 'text-yellow-700'
}

const success = {
  container: 'bg-green-50',
  heading: 'text-green-800',
  description: 'text-green-700'
}

export const styles = {
  base: {
    container,
    heading,
    description
  },
  error,
  warning,
  success
}
