import React, { forwardRef } from 'react'
import cn from 'classnames'

interface BoxProps {
  children?: React.ReactNode
  className?: string
}

export const Box = forwardRef<HTMLDivElement, BoxProps>((props, ref) => {
  const { className: defaultClassName, children, ...rest } = props
  const className = cn('box-border margin-0 min-w-0', defaultClassName)
  return (
    <div ref={ref} className={className} {...rest}>
      {children}
    </div>
  )
})
