import React from 'react'
import { Menu } from '@headlessui/react'
import cn from 'classnames'
import { ChevronDownIcon } from '@heroicons/react/solid'

type DropdownType = React.FC<{ className?: string }> & {
  Button: React.FC<Omit<typeof Menu.Button, 'displayName'> & { className?: string }>
  Items: React.FC<Omit<typeof Menu.Items, 'displayName'> & { className?: string }>
  Item: React.FC<Omit<typeof Menu.Item, 'displayName'> & { className?: string }>
}

const styles = {
  container: 'relative inline-block text-left',
  button:
    'inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500',
  items:
    'absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
}

const Dropdown: DropdownType = ({ className, ...props }: { className?: string }) => (
  <Menu as="div" className={cn(styles.container, className)} {...props} />
)

Dropdown.Button = ({ className, ...props }: React.PropsWithChildren<{ className?: string }>) => (
  <Menu.Button className={cn(styles.button, className)} {...props}>
    {props.children}
    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
  </Menu.Button>
)

Dropdown.Items = React.forwardRef(({ className, ...props }: { className?: string }) => (
  <Menu.Items className={cn(styles.items, className)} {...props} />
))

Dropdown.Item = React.forwardRef(({ className, ...props }: { className?: string }) => <Menu.Item {...props} />)

export { Dropdown }
