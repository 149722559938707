import cn from 'classnames'

const overlay = (isOpen: boolean) =>
  cn(
    'fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity ',
    { 'opacity-0 ease-out duration-300': !isOpen },
    { 'opacity-100 ease-in duration-200': isOpen }
  )

const modal = (isOpen: boolean, size: 'sm' | 'md' | 'lg' | 'xl') =>
  cn(
    'p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl',
    { 'max-w-xl': size === 'sm' },
    { 'max-w-2xl': size === 'md' },
    { 'max-w-3xl': size === 'lg' },
    { 'max-w-4xl': size === 'xl' },
    { 'ease-out duration-300 opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95': !isOpen },
    { 'ease-in duration-200 opacity-100 translate-y-0 sm:scale-100': isOpen }
  )

const container = 'fixed inset-0 z-10 overflow-y-auto'

const closeButton =
  'bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'

export const styles = {
  overlay,
  modal,
  container,
  closeButton
}
