import React from 'react'
import cn from 'classnames'

interface LoaderProps {
  className?: string
  isActive?: boolean
}

const baseStyle = 'w-10 h-10 rounded-full'

export const Loader: React.FC<LoaderProps> = (props) => {
  const { className, isActive = true } = props
	const style = cn(className, baseStyle)
  return (
    <div
      className={cn(style, 'relative border-4 border-gray-200', { 'animate-spin': isActive })}
      style={{ borderLeftColor: 'white' }}
    >
      <div className={style} />
    </div>
  )
}
